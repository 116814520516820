import { Box, styled } from "@mui/material";
import { FC } from "react";

export interface GridBoxProps {
  background?: string;
  columns?: number;
  gridGap?: number;
  children?: React.ReactNode;
  style?: any
}

const GridBox: FC<GridBoxProps> = (props) => {
  const columns = props.columns || 3;
  const GBox = styled(Box)(({ theme }) => ({
    background: props.background || 'green',
    backgroundRepeat: 'repeat',
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    columnGap: theme.spacing(props.gridGap!),
    rowGap: theme.spacing(props.gridGap!),
    padding: theme.spacing(5),
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: `repeat(2, 1fr)`,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: `repeat(1, 1fr)`,
      padding: theme.spacing(2.25),
    }
  }));

  return (
    <GBox style={props.style || {}}>
      {props.children}
    </GBox>
  );
};

export default GridBox;
