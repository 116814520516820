import React, { useState, useEffect, FC, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import latinize from 'latinize';
import { SearchResponse } from "../../../../types";
import { Box, Pagination } from "@mui/material";
import GridBox from "../../../common/GridBox/GridBox";
import Result from "../Result/Result";
import Loader from "../../../common/Loader/Loader";
import { CenteredBox } from "../../../common/CenteredBox/CenteredBox";
import { ONE_DAY_IN_MS } from "../../../../constants";
import NoResultsFound from "../../../common/NoResultsFound/NoResultsFount";
import { useSelector } from "react-redux";
import { subsidiarySelector } from "../../../../store/selectors";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../../routes/Paths";

const { REACT_APP_BP_HOST } = process.env;

export interface ResultsProps {
  search?: string;
  tag?: string;
}

// TODO: Use StudyService
async function apiCall({
  search,
  subsidiary,
  tag = '',
  page,
  epp
}: {
  search: string,
  subsidiary: string,
  tag?: string,
  page: number,
  epp: number
}): Promise<SearchResponse> {
  const res = await fetch(REACT_APP_BP_HOST + '/estudios', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      Accept: '*/*',
      'bp-api-key': 'bp-asdfASDF1234567890',
    }),
    body: JSON.stringify({
      search,
      subsidiary,
      tag,
      page,
      epp,
    })
  });

  if (res.status > 399) {
    const error = await res.json();
    console.error(error.message);
    return {
      data: [],
      page: 1,
      epp: 10,
      total: 0,
    };
  }

  return res.json();
}

const Results: FC<ResultsProps> = ({ search = '', tag = '' }) => {
  const [page, setPage] = useState(1);
  const elementsPerPage = 10;

  const subsidiary = useSelector(subsidiarySelector);
  const previousSearch = useRef(search);
  const navigate = useNavigate();

  const handlePageChange = (event: any, value: number) => {
    setPage(value);
  };

  const emptyResponse: SearchResponse = {
    data: [],
    epp: 12,
    page: 1,
    total: 0
  }

  const { isFetching, isLoading, data: response = emptyResponse, error } = useQuery<unknown, unknown, SearchResponse>({
    queryKey: [search, page, subsidiary, tag],
    queryFn: async () => apiCall({
      search,
      subsidiary: latinize(subsidiary.name?.toLowerCase() || ''),
      tag,
      page,
      epp: emptyResponse.epp
    }),
    keepPreviousData: true,
    staleTime: ONE_DAY_IN_MS,
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (error) {
      return;
    }

    if (isFetching) {
      window.scrollTo({ top: 0 })
    }

    if (search !== previousSearch.current) {
      handlePageChange(null, 1);
      previousSearch.current = search;
    }

    // Do something with the data
  }, [isLoading, isFetching, error, search]);

  const onResultClick = (sku: string) => {
    navigate(Paths.DETAILS.replace(':sku', sku));
  };

  const renderCatalog = () => {
    return (
      <>
        {!response.data.length && <NoResultsFound />}
        <div>
          {response && (
            <GridBox background="white" columns={3} gridGap={2}>
              {response.data.map((result) => (
                <Result onResultClick={onResultClick} study={result} key={result.sku} name={`${result.sku} - ${result.name}`} iconUrl={result.imageUrl || ''} />
              ))}
            </GridBox>
          )}
        </div>
        <Pagination
          sx={{ display: 'flex', justifyContent: 'center' }}
          defaultPage={1}
          page={page}
          onChange={handlePageChange}
          count={response?.total % elementsPerPage > 0 ? Math.floor(response.total / elementsPerPage) + 1 : response.total / elementsPerPage}
          color="standard" />
      </>
    )
  }

  return (
    <Box>
      {!isFetching ? renderCatalog() : <CenteredBox sx={{ marginTop: 8 }}><Loader width={300} /></CenteredBox>}
    </Box>

  );
};

export default React.memo(Results);