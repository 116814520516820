import { FC, Suspense, useCallback } from "react";
import { Box, Button, Typography, styled } from "@mui/material";
import FlexBox from "../common/FlexBox/FlexBox";
import GridBox from "../common/GridBox/GridBox";
import { CenteredBox } from "../common/CenteredBox/CenteredBox";
import DiscountBanner from "./components/DiscountBanner/DiscountBanner";
import { Study as StudyObject } from "../../types";
import { Await, useLoaderData } from "react-router-dom";
import Loader from "../common/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { shouldChooseSelector, subsidiarySelector } from "../../store/selectors";
import StrikeText from "../common/StrikeText/StrikeText";
import StudyHeader from "./components/StudyHeader";
import StudySections from "./components/StudySections";
import { setChooseSubsidiary } from "../../store/reducers/subsidiary";
import Refresh from "../common/Redirect/Redirect";
import latinize from "latinize";
import WhatsAppWrapper from "../common/WhatsappWrapper/WhatsAppWrapper";

const reload = () => {
  window.location.reload();
};

const DescImg = styled('img')(({ theme }) => ({
  marginRight: theme.spacing(1.2),
  objectFit: 'contain',
  width: theme.spacing(3),
}));

// TODO: Modularize
const Study: FC<{}> = () => {
  const dispatch = useDispatch();
  const subsidiary = useSelector(subsidiarySelector);
  const openModal = useSelector(shouldChooseSelector);

  const { data } = useLoaderData() as { data: Promise<StudyObject> };

  const renderLoader = useCallback(() => (
    <CenteredBox sx={{ marginTop: 8 }}><Loader width={300} /></CenteredBox>
  ), []);


  const renderStudy = useCallback((studyResult: StudyObject) => {
    const isDiscount = studyResult.price?.isDiscount;

    const subsidiaryHasChanged = subsidiary.name &&
      (latinize(subsidiary.name.toLocaleLowerCase()) !== studyResult.price.subsidiary);
    if (subsidiaryHasChanged) {
      reload();
    }

    const whatsAppHomeMessage = `Hola 👋.%0a Quisiera que me realizaran el estudio "${studyResult.name}" en mi domicilio`;

    const values = [{
      title: 'Descripción',
      value: studyResult.description,
    }, {
      title: 'Indicaciones',
      value: studyResult.indications,
    }];

    if (studyResult.secondaryDescription) {
      values.push({
        title: '¿Qué incluye?',
        value: studyResult.secondaryDescription,
      });
    }

    return (<>
      {isDiscount && <DiscountBanner studyPrice={studyResult.price} />}
      <GridBox background="transparent" columns={2} style={{
        gridGap: 30,
        marginTop: 16,
        padding: 0,
      }}>
        {/* Left Side */}
        <Box>
          <StudyHeader
            methodology={studyResult.methodology || ''}
            name={studyResult.name!}
            sku={studyResult.sku}
            kindOfSample={studyResult.kindOfSample}
          />
          <StudySections values={values} />
          <Typography fontSize={15} variant="h6">
            Los ensayos pueden ser procesados por Laboratorios Médicos BP y/o subrogarse
          </Typography>
          <br />
          <Typography fontSize={15} variant="h6">
            <sup>*</sup>
            Ensayo acreditado por ema, a.c. con acreditación No. CL-031 (CDMX). Acreditación perteneciente al centro analítico Orthin Referencia Especializada SA de CV.
          </Typography>
        </Box>

        {/* Right Side */}
        <FlexBox sx={{ marginTop: 7 }}>
          <FlexBox sx={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-around', columnGap: 3 }}>
            {isDiscount && (
              <Box sx={{ alignItems: 'center', display: 'flex', flexGrow: 2, justifyContent: 'flex-end' }}>
                <StrikeText color="grey">${studyResult.price.referencePrice}</StrikeText>
              </Box>

            )}
            <Box sx={{ display: 'flex', flexGrow: 2, justifyContent: isDiscount ? 'flex-start' : 'center' }}>
              <Typography
                variant="h1"
                color='primary.light'
                fontWeight='normal'
                style={{ justifyContent: 'flex-start' }}
              >
                ${studyResult.price.value}
              </Typography>
            </Box>

          </FlexBox>

          {/* Indicators */}
          <Box sx={{ display: 'flex', columnGap: 3, marginTop: 3 }}>
            {/* TODO: Hide typography and icon only. Box would break syle */}
            <Typography variant='h6'
              sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
              <DescImg src="/icons/time.png" title="Tiempo de entrega" />
              {studyResult.timeOfDelivery}
            </Typography>
            <Typography variant='h6'
              sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-start' }}>
              <DescImg src="/icons/calendar.png" title="¿Requiere cita?" />
              {studyResult.isAppointmentRequired ? 'Requiere cita previa' : 'No requiere cita previa'}
            </Typography>
          </Box>

          {/* Sucursal icon */}
          <Box sx={{ flexWrap: 'nowrap', display: 'flex', alignItems: 'center', marginBottom: 1.3, marginTop: 6 }}>
            <DescImg src="/icons/location-dot.png" />
            <Typography color="secondary" sx={{ padding: 0 }}>Tu sucursal</Typography>
          </Box>


          {/* Change subsidiary */}
          <Box sx={{
            alignItems: 'center',
            backgroundColor: '#4e71b1',
            borderRadius: 1,
            columnGap: 3,
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            padding: 2
          }}>
            <Typography color='white' variant="link">Sucursal {subsidiary.name}</Typography>
            <Button variant="contained"
              onClick={() => { dispatch(setChooseSubsidiary(true)) }}
              color="light" disableElevation>Cambiar</Button>
          </Box>

          {/* Study in house */}
          <CenteredBox sx={{ justifyContent: 'center' }}>
            {studyResult.isElegibleAtHome && (
              <Box>
                <Box>
                  <Typography fontSize={20} variant="h6">Estudio disponible para realizar en casa ¡Contáctanos!</Typography>
                  <Typography align="center" variant="h6">(Disponibilidad: {studyResult.deliveryMessage})</Typography>
                </Box>
                <CenteredBox sx={{ marginTop: 3 }}>
                  <WhatsAppWrapper number="+525633575330" message={whatsAppHomeMessage}>
                    <Button sx={{ background: '#00BB2D' }}
                      variant="contained"
                    >Whatsapp</Button>
                  </WhatsAppWrapper>
                </CenteredBox>
              </Box>
            )}
          </CenteredBox>

          {/* Cotizar */}
          {/* <CenteredBox sx={{ marginTop: 5 }}>
            <Button variant="contained" color="light">Cotizar</Button>
          </CenteredBox> */}

        </FlexBox>
      </GridBox>
    </>
      // eslint-disable-next-line react-hooks/exhaustive-deps
    )
  }, [openModal]);

  return (
    <Suspense fallback={renderLoader()}>
      <Await resolve={data} errorElement={<Refresh />}>
        {({ study }) => renderStudy(study)}
      </Await>
    </Suspense>
  );
};

export default Study;