import { Box, styled } from '@mui/material';

const RibbonBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: theme.palette.primary.main,
  color: 'white',
  display: 'flex',
  height: theme.spacing(6),
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }
}));

export default RibbonBox;