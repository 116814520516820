import { keyframes } from '@emotion/react'
import { styled } from '@mui/material';
import { FC, memo } from "react";

interface LoaderProps {
  width?: number
}

const blink = keyframes`
  from {
    opacity: 0;
  }

  50% {
    opacity: 0.4;
  }

  to {
    opacity: 0;
  }
`
const LoaderImg = styled('img')(() => ({
  animation: `${blink} 1s ease infinite`
}));

const Loader: FC<LoaderProps> = ({ width }) => {
  return (
    <>

      <LoaderImg width={width || 100} src="/icons/bp-l.webp" alt="loading..." />
    </>
  );
}

export default memo(Loader);
