import { Box, styled } from "@mui/material";


const FooterBox = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  marginTop: theme.spacing(8),
  '> p': {
    fontSize: theme.spacing(2.375),
    padding: 0
  },
  '> a': {
    color: 'white',
    fontSize: theme.spacing(2.375),
  },
  [theme.breakpoints.down('md')]: {
    padding: `0 ${theme.spacing(2.25)}`,
  },
}));

export default FooterBox;
