import { Typography, styled, useTheme } from "@mui/material";
import { FC } from "react";

export interface TabTextProps {
  children: string;
  onClick?: () => void;
  highlight?: boolean;
}

const UnderlineTypography = (color: string) => styled(Typography)(({ theme }) => ({
  borderBottom: '3px solid',
  borderColor: color,
  cursor: 'pointer',
  fontSize: theme.spacing(2.5),
  paddingBottom: theme.spacing(1.5),
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.spacing(2.125),
  }
}));

const ExtendedTypography = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  fontSize: theme.spacing(2.5),
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.spacing(2.125),
  }
}));

const TabText: FC<TabTextProps> = ({ children, onClick, highlight = false }) => {
  const theme = useTheme();
  const UnderlineTypo = UnderlineTypography(theme.palette.primary.light)
  return (
    <>
      {highlight ? (
        <UnderlineTypo variant="h6" onClick={onClick}>{children}</UnderlineTypo>
      ) : (
        <ExtendedTypography variant="h6" onClick={onClick}>{children}</ExtendedTypography>
      )}
    </>
  );
};

export default TabText;
