import { Box, Typography } from "@mui/material";
import { FC } from "react";

interface StudyHeaderProps {
  icon?: string;
  methodology: string;
  kindOfSample: string;
  category?: string;
  name: string;
  sku: string;
}

const StudyHeader: FC<StudyHeaderProps> = ({ icon, methodology, name, sku, kindOfSample }) => {
  return (
    <Box sx={{
      textAlign: 'center'
    }}>
      <img style={{ width: 64 }} src={icon || '/icons/resp.png'} width="100%" alt='' />
      <Typography variant="h1" sx={{ marginBottom: 2 }} fontSize={40} fontWeight={'normal'}>{name}</Typography>
      <Typography variant="body1" fontSize={20}>{methodology}</Typography>
      <Typography variant="body1" fontSize={20}>{kindOfSample}</Typography>
      <Typography variant="body1" fontSize={20} color={'secondary'}>Clave {sku}</Typography>
    </Box >
  )
};

export default StudyHeader;
