import { Typography, styled } from '@mui/material';

export interface RibbonTextProps {
  align: 'start' | 'end';
}

const RibbonText = styled(Typography)(({ theme }) => ({
  color: 'white',
  display: 'flex',
  fontFamily: 'Modernist',
  fontSize: 17,
  fontWeight: 'lighter',
  padding: 0,
  ':hover': {
    textDecoration: 'underline'
  }
}));

export default RibbonText;