import { Box, Typography, styled } from "@mui/material";
import FlexBox from "../FlexBox/FlexBox";
import { FC } from "react";
import { Subsidiary } from "../../../types";

const ModalBox = styled(FlexBox)(({ theme }) => ({
  background: 'white',
  borderRadius: theme.spacing(1),
  display: 'grid',
  gridAutoColumns: 'minmax(100px, auto)',
  gridTemplateRows: '15% 85%',
  left: '50%',
  margin: '0 auto',
  maxHeight: '70%',
  maxWidth: '1280px',
  padding: theme.spacing(6),
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  [theme.breakpoints.down('md')]: {
    maxWidth: '85%',
    width: 'max-content'
  }
}));

export interface SubsidiarySelectionProps {
  allSubsidiaries: Subsidiary[];
  preferredSubsidiary?: Subsidiary;
  onSelectSubsidiary?: (subsidiary: Subsidiary) => void;
}

const SubsidiaryOptionBox = styled(Box)(({ theme }) => ({
  borderBottom: '1px solid #cfcfcf',
  cursor: 'pointer',
  display: 'flex',
  paddingBottom: 0,
  width: '100%',
}));

interface SubsidiaryOptionProps {
  option: Subsidiary;
  onSelectSubsidiary: (s: Subsidiary) => void;
}

const SubsidiaryOption: FC<SubsidiaryOptionProps> = ({ option, onSelectSubsidiary }) => {
  return (
    <>
      <SubsidiaryOptionBox
        sx={{
          borderRadius: 2,
          minHeight: 64,
          padding: 0.5,
          ':hover': {
            background: 'rgba(0,0,0,0.1)'
          }
        }}
        onClick={() => onSelectSubsidiary(option)}
      >
        <Box sx={{
          alignItems: 'center',
          display: 'flex',
          flex: 0.7,
          justifyContent: 'center'
        }}>
          <img style={{
            padding: '2%',
            display: 'block',
            maxHeight: '80%',
            maxWidth: '100%'
          }} src='/icons/location-dot.png' alt={option?.name} />
        </Box>
        <Box sx={{ display: 'flex', flex: 1.3, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
          <Typography color="primary" variant="h4">{option.name}</Typography>
          <br></br>
          <Typography variant="body1">{option.address}</Typography>
        </Box>
      </SubsidiaryOptionBox>
    </>
  );
};

const SubsidiarySelection: FC<SubsidiarySelectionProps> = ({
  allSubsidiaries, preferredSubsidiary, onSelectSubsidiary
}) => {
  return (
    <ModalBox>
      <Box sx={{
        borderRadius: 2,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gridRow: 1,
        justifyContent: 'space-around',
        width: '100%'
      }}>
        <Box sx={{
          paddingBottom: 3,
          alignItems: 'flex-start',
          display: 'flex'
        }}>
          <Typography sx={{ padding: 0 }} align="center" variant="h3">¿Dónde realizarás tus estudios?</Typography>
        </Box>
        {preferredSubsidiary && (
          <Box>
            <Typography align="center" color="primary.light" fontWeight='500' >
              Selección: {preferredSubsidiary.name}
            </Typography>
          </Box>
        )}

      </Box>


      <FlexBox sx={{
        background: '',
        gridRow: 2,
        '> *': {
          justifyContent: 'center',
        }
      }}>
        <Box sx={{
          alignItems: 'flex-start',
          gridColumn: 1,
          columnSpan: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
          {/* Lista de sucursales */}
          {allSubsidiaries.map(s => (
            <SubsidiaryOption
              option={s}
              onSelectSubsidiary={() => onSelectSubsidiary?.(s)} />
          ))}
        </Box>
      </FlexBox>
    </ModalBox >
  );
};

export default SubsidiarySelection;
