import { SubsidiaryResponse } from "../types";
import { ServiceBase } from "./ServiceBase";

export class SubsidiaryService extends ServiceBase {
  static apiUrl = process.env.REACT_APP_BP_HOST;

  async getSubsidiaries() {
    const subsidiaries = await this.getRequest<SubsidiaryResponse>({
      method: 'GET',
      url: '/sucursales'
    });

    return subsidiaries;
  }
}
