import { FC } from "react";
import { Link, styled } from "@mui/material";
import { CenteredBox } from "../CenteredBox/CenteredBox";
import { Link as RouterLink } from 'react-router-dom';

const LinksBox = styled(CenteredBox)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: 500,
    justifyContent: 'space-between',
    marginBottom: 24,
    marginTop: 32
  },
  [theme.breakpoints.down('md')]: {
    display: 'block',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(4),
    width: '100',
    '& a': {
      display: 'block',
      fontSize: '1em',
      marginBottom: theme.spacing(3),
      textAlign: 'center',
    }
  }
}));

const UsefulLinks: FC<{}> = () => {
  return (
    <LinksBox>
      <Link component={RouterLink} variant="h6" color="white" to='/' underline="none">Aviso de privacidad</Link>
      <Link component={RouterLink} variant="h6" color="white" to="/nosotros" underline="none">Nosotros</Link>
      <Link component={RouterLink} variant="h6" color="white" to="" underline="none">Contacto</Link>
      <Link component={RouterLink} variant="h6" color="white" to="/" underline="none">Inicio</Link>
    </LinksBox>
  );
};

export default UsefulLinks;
