import { FC } from "react";
import FooterBox from "./FooterBox";
import AllianceBar from "./AllianceBar";
import ResponsibleBanner from "./ResponsibleBanner";
import Separator from "../Separator/Separator";
import UsefulLinks from "./UsefulLinks";
import Legal from "./Legal";
import SocialMedia from "./SocialMedia";
import { Box } from "@mui/material";
import { MAX_WIDTH } from "../../../constants";


const Footer: FC<{}> = () => {
  return (
    <>
      <FooterBox>
        <Box sx={{ maxWidth: MAX_WIDTH, margin: '0 auto', padding: { md: '0 24px', } }}>
          <AllianceBar />
          <Separator color="white" />
          <UsefulLinks />
          <SocialMedia />
          <ResponsibleBanner />
        </Box>

      </FooterBox>
      <Legal />
    </>

  );
};

export default Footer;