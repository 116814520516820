import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Subsidiary } from "../../types";
import { SUBSIDIARY_STORAGE_NAME } from "../../constants";

interface SubsidiariesState {
  all: Subsidiary[];
  preferred: Partial<Subsidiary>;
  shouldChoose: boolean;
}

const subsidiaryString = sessionStorage.getItem(SUBSIDIARY_STORAGE_NAME);

const preferred = subsidiaryString
  ? JSON.parse(subsidiaryString)
  : undefined;

const initialState: SubsidiariesState = {
  preferred,
  all: [],
  shouldChoose: Boolean(preferred === undefined),
};

export const subsidiarySlice = createSlice({
  name: 'subsidiaries',
  initialState,
  reducers: {
    setSubsidiaries: (state, action: PayloadAction<Subsidiary[]>) => {
      state.all = action.payload;
    },
    setPreferredSubsidiary: (state, action: PayloadAction<Subsidiary>) => {
      sessionStorage.setItem(SUBSIDIARY_STORAGE_NAME, JSON.stringify(action.payload));
      state.preferred = action.payload;
    },
    setChooseSubsidiary: (state, action: PayloadAction<boolean>) => {
      state.shouldChoose = action.payload;
    }
  }
});

export const { setSubsidiaries, setPreferredSubsidiary, setChooseSubsidiary } = subsidiarySlice.actions;

export default subsidiarySlice.reducer;
