import { FC } from "react";
import { CenteredBox } from "../../../common/CenteredBox/CenteredBox";
import { Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import MedicArea from "./MedicArea";
import GridBox from "../../../common/GridBox/GridBox";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../../routes/Paths";

const FlexBox = styled(CenteredBox)(({ theme }) => ({
  flexFlow: 'row wrap',
  '& *': {
    flexBasis: '100%'
  }
}));

const style = {
  backgroundColor: '#fff',
  backgroundImage: 'radial-gradient(circle, #D1D1D6 15%, transparent 10%), radial-gradient(circle, #D1D1D6 15%, transparent 10%)',
  backgroundSize: '18px 18px',
  backgroundPosition: '-5px -5px, 5px 5px',
}

const MedicalAreas: FC<{}> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const onAreaClick = (area: string) => {
    navigate({ pathname: Paths.CATALOG, search: `search=${area}` });
  };


  return (
    <FlexBox>
      <Typography fontWeight={'lighter'} variant="h1" align="center">Áreas médicas</Typography>
      {/* TODO: Make it a component */}
      {!isMobile && (
        <CenteredBox sx={{ margin: '24px 0', flex: { lg: 0.5, md: 0.6, xs: 0.7 }, flexBasis: '50%', textAlign: 'center' }}>
          <Typography variant="h6">Las más populares</Typography>
          <Typography variant="h6">Para ellas</Typography>
          <Typography variant="h6">Para ellos</Typography>
        </CenteredBox>
      )}

      <GridBox style={style} columns={3} gridGap={5} >
        <MedicArea onClick={() => onAreaClick('respiratorias')} name="Enfermedades respiratorias" iconUrl="icons/resp.png" />
        <MedicArea onClick={() => onAreaClick('sexual')} name="Salud sexual" iconUrl="icons/sexual.png" />
        <MedicArea onClick={() => onAreaClick('tiroides')} name="Hormonas sexuales y tiroideas" iconUrl="icons/tiroides.png" />
        <MedicArea onClick={() => onAreaClick('embarazo')} name="Embarazo" iconUrl="icons/embarazo.png" />
        <MedicArea onClick={() => onAreaClick('deportes')} name="Deportivos" iconUrl="icons/deportivos.png" />
        <MedicArea onClick={() => onAreaClick('diabetes')} name="Diabetes" iconUrl="icons/diabetes.png" />
      </GridBox>
    </FlexBox>
  );
};

export default MedicalAreas;
