import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { BPHeaderLink, Links } from './Links';
import { Paths } from '../../../routes/Paths';

export interface HeaderProps {
  links?: BPHeaderLink[];
};

const Header: FC<HeaderProps> = ({ links = Links }) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position='relative'
      color='transparent'
      elevation={0}
    >
      <Container maxWidth="xl">
        <Toolbar sx={{
          height: {
            md: theme.spacing(12.5),
            xs: theme.spacing(10)
          },
          padding: {
            xs: 0,
            md: `0 ${theme.spacing(2)}`,
            background: 'white',
          },
        }} >
          {/* Mobile menu */}
          <Box
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, flexDirection: 'row-reverse' }}
          >
            <Menu
              id='bp-nav'
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              keepMounted
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {links.map(page => (
                <MenuItem key={page.title} >
                  <Button>
                    <NavLink
                      data-testid={`${page.title}-link`}
                      style={{ color: 'black', textDecoration: 'none' }}
                      to={page.link} onClick={handleCloseNavMenu}>{page.title}</NavLink>
                  </Button>
                </MenuItem>
              ))}
            </Menu>
            <Link to={Paths.ROOT} style={{ display: 'flex', flexGrow: 2 }}>
              <img style={{ height: theme.spacing(6.5), textAlign: 'center' }} src="/logo.webp" alt="BP Lab" />
            </Link>
            <Box sx={{ flexGrow: 1.5 }} ></Box>
            <IconButton
              sx={{ flexGrow: 0.5, width: '10' }}
              onClick={handleOpenNavMenu}
              data-testid="menu-mobile">
              <FontAwesomeIcon icon={faBars} />
            </IconButton>

          </Box>
          {/* Desktop menu */}
          <Box sx={{ flexGrow: 1, alignItems: 'center', display: { xs: 'none', md: 'flex' }, marginTop: '8px' }}>
            <Link to={Paths.ROOT} style={{ flexGrow: 1 }}>
              <img style={{ height: theme.spacing(10) }} src="/logo.webp" alt="BP Lab" />
            </Link>
            {links.map(page => (
              <NavLink to={page.link} key={page.title} style={{ textDecoration: 'none' }}>
                {/* <Link to={page.link} key={page.title} style={{ color: 'black', display: 'block' }} onClick={handleCloseNavMenu}
                > */}
                <Typography variant='button' style={{ marginLeft: 20, fontSize: 17, textTransform: 'capitalize', color: 'black' }}>{page.title}</Typography>
                {/* </Link> */}
              </NavLink>

            ))}
          </Box>

        </Toolbar>
      </Container>
    </AppBar >
  );
};

export default Header;