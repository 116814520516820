import { FC } from "react";
import TabText from "../TabText/TabText";
import { CenteredBox } from "../CenteredBox/CenteredBox";
import { styled } from "@mui/material";

interface LinksProps {
  links: {
    value: string;
    text: string;
  }[];
  selected?: string;
  onSelectValue: (value: string) => void
}


const FlexBox = styled(CenteredBox)(({ theme }) => ({
  flexFlow: 'row wrap',
  justifyContent: 'flex-start',
  columnGap: theme.spacing(6),
  '& *': {
  }
}));

const HorizontalLinks: FC<LinksProps> = ({ links, selected = '', onSelectValue }) => {
  return (
    <>
      <FlexBox sx={{ margin: 0, justifyContent: 'flex-start' }}>
        {links.map(link =>
          <TabText key={link.value} highlight={selected === link.value} onClick={() => onSelectValue(link.value)}>{link.text}</TabText>
        )}
      </FlexBox>
    </>
  )
};

export default HorizontalLinks;
