import { LoaderFunctionArgs, defer } from "react-router-dom";
import { SearchResponse, SearchStudyResponse, Study } from "../types";
import { SUBSIDIARY_STORAGE_NAME } from "../constants";
import latinize from "latinize";

const mockData: Partial<Study>[] = [{
  name: 'Prueba COVID Antígenos',
  methodology: 'Cromatología',
  sku: '2345',
  price: {
    subsidiary: 'Coyuya',
    value: 845.00,
    isDiscount: 0,
  }
}, {
  name: 'Perfil hepático',
  methodology: 'Muestra de sangre',
  sku: '2346',
  price: {
    subsidiary: 'Coyuya',
    value: 250.00,
    isDiscount: 0,
  }
}, {
  name: 'name',
  methodology: 'catego',
  sku: '2347',
  price: {
    subsidiary: 'Coyuya',
    value: 1500.00,
    isDiscount: 0,
  }
}, {
  name: 'name',
  methodology: 'catego',
  sku: '2348',
  price: {
    subsidiary: 'Coyuya',
    value: 999.00,
    isDiscount: 4,
    referencePrice: 1050.00
  }
}];

export class StudyService {
  static apiUrl = process.env.REACT_APP_BP_HOST;

  static async getStudies(search: string, subsidiary: string, page: number, epp: number): Promise<SearchResponse> {
    const res = await fetch(StudyService.apiUrl + '/estudios', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: '*/*',
        'bp-api-key': 'bp-asdfASDF1234567890',
      }),
      body: JSON.stringify({
        search,
        subsidiary,
        page,
        epp,
      })
    });

    if (res.status > 399) {
      const error = await res.json();
      console.error(error.message);
      return {
        data: mockData,
        page: 1,
        epp: 10,
        total: mockData.length,
      };
    }

    return res.json();
  }

  static loadStudy(args: LoaderFunctionArgs) {
    const { params } = args;
    const subsidiaryString = sessionStorage.getItem(SUBSIDIARY_STORAGE_NAME);
    const subsidiary = subsidiaryString ? JSON.parse(subsidiaryString) : {};

    return defer({ deferredStudy: StudyService.getStudy(params.sku as string, latinize(subsidiary.name?.toLowerCase() || '')) });
  }

  static async getStudy(sku: string, subsidiary?: string): Promise<SearchStudyResponse> {
    const res = await fetch(`${StudyService.apiUrl}/estudios/${sku}?s=${subsidiary}`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: '*/*',
        'bp-api-key': 'bp-asdfASDF1234567890',
      }),
    });

    if (res.status > 399) {
      const error = await res.json();
      console.error(error.message);
      throw error;
    }

    return res.json();
  }
}
