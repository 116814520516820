import React, { FC, useRef } from "react";
import { CenteredBox } from "../common/CenteredBox/CenteredBox";
import { Box, Button, TextField, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../routes/Paths";

const SearchBox = styled(CenteredBox)(({ theme }) => ({
  alignItems: 'center',
  background: 'white',
  borderRadius: theme.spacing(0.5),
  boxShadow: `${theme.spacing(0.25)} ${theme.spacing(0.25)} ${theme.spacing(1.75)} rgba(0, 0, 0, 0.1)`,
  columnGap: theme.spacing(5),
  display: 'flex',
  justifyContent: 'space-between',
  marginLeft: '200px',
  marginRight: '200px',
  padding: `${theme.spacing(2.5)} ${theme.spacing(4)}`,
  position: 'relative',
  textAlign: 'center',
  top: `-${theme.spacing(4)}`,
  zIndex: 2,
  [theme.breakpoints.down('lg')]: {
    display: 'block',
    margin: `0 ${theme.spacing(2)}`,
    top: `${theme.spacing(2)}`,
  },
  '> *': {
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(2),
    }
  }
}));

const ShrinkBox = styled(Box)(({ theme }) => ({
  height: theme.spacing(5),
  display: 'flex'
}))

const BarText = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2),
  lineHeight: theme.spacing(2.5),
  position: 'relative',
  wordWrap: 'break-word',
  paddingBottom: 0,
  [theme.breakpoints.down('lg')]: {
    top: 0
  }
}));

const SearchBar: FC<{}> = () => {
  const navigate = useNavigate();
  const searchInput = useRef<HTMLInputElement>();

  const onSearch = (evt: React.MouseEvent) => {
    const search = searchInput.current?.value;
    if (!search) return;

    navigate({ pathname: Paths.CATALOG, search: `search=${search}` });
  };

  return (
    <SearchBox>
      {/* <ShrinkBox sx={{ flex: 2 }}>
        <BarText variant="caption">Busca un estudio</BarText>
      </ShrinkBox> */}
      <ShrinkBox sx={{ flex: 2 }}>
        <TextField
          inputRef={searchInput}
          fullWidth
          placeholder="Busca un estudio"
          size="small"
          type="text"
          variant="outlined" />
      </ShrinkBox>
      <ShrinkBox justifyContent={'center'} sx={{ flex: 1 }}>
        <Button
          onClick={onSearch}
          color="light"
          variant="contained"
          disableElevation>Buscar</Button>
      </ShrinkBox>
    </SearchBox>
  );
}

export default SearchBar;