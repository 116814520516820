import { FC } from "react";
import BannerBox from "../common/BannerBox/BannerBox";
import { BannerImage } from "../LandingPage/components/BannerImage/BannerImage";
import { Box, Button, Typography, styled } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Paths } from "../../routes/Paths";

interface CovidCardProps {
  variant: string;
  sku: string;
  description: string;
  sampling: string;
  results: string;
}

const CovidCardBox = styled(Box)(({ theme }) => ({
  background: 'url(covid-card.png) no-repeat',
  color: 'white',
  height: 296,
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    minWidth: '409px',
    maxHeight: '296px'
  },
  [theme.breakpoints.down('md')]: {
    backgroundSize: 'contain',
    maxHeight: '260px',
    minWidth: '100%'
  }
}));

const CovidCard: FC<CovidCardProps> = ({ sku, variant, description, sampling, results }) => {
  const navigate = useNavigate();
  // type, price, sampling
  return (
    <Box sx={{
      display: 'flex',
      marginBottom: 5,
      columnGap: 3,
      '& pre': {
        margin: 0
      },
      flexDirection: { lg: 'row', md: 'row', sm: 'column', xs: 'column' }
    }}>
      <CovidCardBox>
        <Typography variant="h2" align="center" style={{ marginTop: 40 }}>Prueba Covid 19</Typography>
        <Typography align="center" fontSize={30} fontWeight='light' style={{ marginTop: 24, marginBottom: 16 }} >{variant}</Typography>
        <Button color="light" variant="contained" onClick={() => navigate(Paths.DETAILS.replace(':sku', sku))}>Detalles</Button>
      </CovidCardBox>
      <Box>
        <Typography color="primary" variant="h2" style={{ marginBottom: 16 }}>¿Cómo funciona esta prueba?</Typography>
        <Typography variant="caption" align="justify" style={{ paddingBottom: 0 }}>
          <pre style={{ fontFamily: 'inherit', whiteSpace: 'break-spaces' }}>
            {description}
          </pre>
        </Typography>
        <br />
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row' }, marginTop: 1.5, textAlign: { xs: 'center', sm: 'center', md: 'justify' } }}>
          <Box sx={{ margin: '0 auto' }}>
            <Typography variant="h2" color="primary.light" fontWeight='bold'>Muestreo</Typography>
            <Typography>{sampling}</Typography>
          </Box>
          <Box sx={{ margin: '0 auto' }}>
            <Typography variant="h2" color="primary.light" fontWeight='bold'>Resultados</Typography>
            <Typography>{results}</Typography>
          </Box>
        </Box>
      </Box>
    </Box >
  );
};


const CovidTests: FC<{}> = () => {
  const cards: CovidCardProps[] = [{
    variant: 'Antígenos (hisopo)',
    sku: '4776',
    description: 'Prueba rápida utilizada como auxiliar para detección de proteínas del virus COVID-19 y recomendable para pacientes con síntomas. Un resultado Negativo no excluyen una infección por COVID-19',
    results: '15 min y certificado para viaje en 2 horas',
    sampling: 'Hisopado Nasal'
  }, {
    variant: 'PCR (hisopo)',
    sku: '4776',
    description: 'Prueba molecular para detección de COVID-19, proporciona mayor certeza en el resultado ya que detecta el material genético del virus. Es recomendable para pacientes con síntomas o asintomáticos (tres días después del contacto con algún caso Positivo). \nEn comparación con el test de Antígenos, la PCR ofrece una mayor sensibilidad al detectar menores cantidades de virus. Ideal para pacientes asintomaticos o para pacientes que estan en etapas iniciales o finales de la infección.',
    results: '15 min y certificado para viaje en 2 horas',
    sampling: 'Hisopado Nasal'
  }, {
    variant: 'Anticuerpos',
    sku: '4776',
    description: 'Prueba que detecta los anticuerpos generados tanto por infección (IgM e IgG) como por Vacunación (IgGII quant). Es recomendada para pacientes que quieran saber si tuvieron la enfermedad (sospecha hace 4 meses o menos) o si tienen protección por la vacunación. \nAc IgM: Anticuerpos de respuesta rápida se presentan en los primeros días de la infección.\nAc IgG: Anticuerpos de respuesta especifica al COVID se detectan en las semanas posteriores de la infección.\nAc IgG II Quant: Anticuerpos específicos de COVID producidos a partir de la vacunación',
    results: 'En 24 horas',
    sampling: 'Muestra Sanguínea'
  }]
  return (
    <>
      <BannerBox>
        <BannerImage alt="banner-covid" src={`banner-covid-lg.png`} />
      </BannerBox>
      {cards.map(c => (
        <CovidCard key={c.sku} {...c} />
      ))}


    </>
  );
};

export default CovidTests;
