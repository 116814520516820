import { Box, styled } from "@mui/material";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { MAX_WIDTH, ONE_DAY_IN_MS } from "../../../constants";
import BPModal from "../BPModal/BPModal";
import { setChooseSubsidiary, setSubsidiaries, } from "../../../store/reducers/subsidiary";
import { shouldChooseSelector, subsidiariesSelector, subsidiarySelector } from "../../../store/selectors";
import { useDispatch, useSelector } from "react-redux";
import { SubsidiaryResponse } from "../../../types";
import { useQuery } from "@tanstack/react-query";
import { SubsidiaryService } from "../../../services/SubsidiaryService";


const BPBox = styled(Box)(({ theme }) => ({
  maxWidth: '100%',
  padding: `0 ${theme.spacing(2)}`,
  [theme.breakpoints.up('lg')]: {
    maxWidth: MAX_WIDTH,
  },
  margin: '0 auto',
}));


const BPOutlet: FC<{}> = () => {
  const subsidiary = useSelector(subsidiarySelector);
  const shouldOpenModal = useSelector(shouldChooseSelector);
  const allSubsidiaries = useSelector(subsidiariesSelector);
  const dispatch = useDispatch();

  const { data: response } =
    useQuery<unknown, unknown, SubsidiaryResponse>({
      queryKey: ['subsidiaries'],
      queryFn: async () => new SubsidiaryService().getSubsidiaries(),
      keepPreviousData: true,
      staleTime: ONE_DAY_IN_MS,
    });

  if (response && !allSubsidiaries.length) {
    dispatch(setSubsidiaries(response.subsidiaries));
  }

  return (
    <BPBox>
      <Outlet />
      <BPModal
        closable={!!subsidiary}
        open={shouldOpenModal}
        onClose={() => setChooseSubsidiary(false)}
      />

    </BPBox>
  );
}

export default BPOutlet;
