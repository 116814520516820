import { createBrowserRouter, defer } from 'react-router-dom';
import { Paths } from './Paths';
import App from '../App';
import LandingPage from '../components/LandingPage/LandingPage';
import Catalog from '../components/Catalog/Catalog';
import ErrorPage404 from '../components/common/404/404';
import Study from '../components/Study/Study';
import Us from '../components/Us/Us';
import { StudyService } from '../services/StudyService';
import latinize from 'latinize';
import { SUBSIDIARY_STORAGE_NAME } from '../constants';
import CovidTests from '../components/CovidTests/CovidTests';

export const Routes = createBrowserRouter([
  {
    path: Paths.ROOT,
    element: <App />,
    children: [
      {
        path: Paths.ROOT,
        element: <LandingPage />,
      },
      {
        path: Paths.US,
        element: <Us />
      },
      {
        path: Paths.CATALOG,
        element: <Catalog />
      },
      {
        path: Paths.COVID_TESTS,
        element: <CovidTests />
      },
      {
        path: Paths.DEFAULT,
        element: <ErrorPage404 />
      },
      {
        path: Paths.DETAILS,
        element: <Study />,
        loader: ({ params }) => {
          const subsidiaryString = sessionStorage.getItem(SUBSIDIARY_STORAGE_NAME);
          const subsidiary = subsidiaryString ? JSON.parse(subsidiaryString) : {};
          console.log(subsidiary);

          return defer({ data: StudyService.getStudy(params.sku as string, latinize(subsidiary.name?.toLowerCase() || '')) });
        },
      }
    ]
  }
])