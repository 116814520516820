import { FC } from "react";
import { CenteredBox } from "../CenteredBox/CenteredBox";
import { Typography, styled } from "@mui/material";

const ResponsibleBox = styled(CenteredBox)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    margin: `auto ${theme.spacing(1)}`,
    textAlign: 'center'
  }
}));

const ResponsibleText = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontFamily: 'SF Display',
  fontSize: theme.spacing(1.5),
  fontWeight: 'lighter',
  [theme.breakpoints.up('md')]: {
    fontSize: theme.spacing(1.75),
  }
}))

const ResponsibleBanner: FC<{}> = () => {
  return (
    <ResponsibleBox>
      <ResponsibleText>Resp. San. Q.F.B. Bruno Hinojosa Estrada Ced. Prof. 9859099 Universidad La Salle</ResponsibleText>
    </ResponsibleBox>
  );
};

export default ResponsibleBanner;
