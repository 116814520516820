import { FC, } from "react";
import Modal from '../Modal/Modal';
import SubsidiarySelection from "../SubsidiarySelection/SubsidiarySelection";
import { useDispatch, useSelector } from "react-redux";
import { shouldChooseSelector, subsidiariesSelector } from "../../../store/selectors";
import { setChooseSubsidiary, setPreferredSubsidiary } from "../../../store/reducers/subsidiary";
import { Subsidiary } from "../../../types";

export interface BPModalProps {
  open?: boolean;
  closable?: boolean;
  onClose?: (e: Event) => void;
}

const BPModal: FC<BPModalProps> = ({ closable = true, open = false, onClose }) => {
  const dispatch = useDispatch();
  const allSubsidiaries = useSelector(subsidiariesSelector);
  const openModal = useSelector(shouldChooseSelector);

  return (
    <Modal
      open={openModal}
      onClose={(e) => {
        if (closable) {
          dispatch(setChooseSubsidiary(false));
          onClose?.(e);
        }
      }}
    >
      <SubsidiarySelection
        allSubsidiaries={allSubsidiaries}
        onSelectSubsidiary={(subsidiary: Subsidiary) => {
          dispatch(setPreferredSubsidiary(subsidiary));
          dispatch(setChooseSubsidiary(false));
          setChooseSubsidiary(false);
        }}
      />
    </Modal>
  );
}

export default BPModal;
