import React, { FC } from 'react';
import './App.css';
import Header from './components/common/Header/Header';
import BPOutlet from './components/common/BPOutlet/BPOutlet';
import BPRibbon from './components/common/BPRibbon/BPRibbon';
import Footer from './components/common/Footer/Footer';

const App: FC<{}> = () => {
  return (
    <>
      <BPRibbon />
      <Header />
      <BPOutlet />
      <Footer />
    </>
  );
}

export default App;
