import { Box, Typography, styled } from "@mui/material";
import { FC } from "react";
import { StudyPrice } from "../../../../types";

interface DiscountBannerProps {
  studyPrice: StudyPrice;
}

const ColoredBanner = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.spacing(0.8),
  color: 'white',
  columnGap: theme.spacing(1),
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  padding: `${theme.spacing(1)} 0`,
  textAlign: 'center',
  width: '100%',
}));

const DiscountBanner: FC<DiscountBannerProps> = ({ studyPrice }) => {
  const { referencePrice, value } = studyPrice;
  if (!value || !referencePrice) {
    return null;
  }
  const discount = Math.floor(
    (referencePrice - value) / referencePrice * 100);

  if (discount < 1 && discount > 100) {
    return null;
  }

  return (
    <ColoredBanner>
      <img src="/icons/discount.png" width={32} style={{ margin: '2px 0' }} alt={`Artículo con el ${discount} % de descuento`} />
      <Typography letterSpacing={2.5} sx={{ verticalAlign: 'center' }} variant="link">Este estudio cuenta con {discount}% de descuento</Typography>
    </ColoredBanner>
  )
};

export default DiscountBanner;