import { FC } from "react"
import { useNavigate } from "react-router-dom";

const Refresh: FC<{}> = () => {
  useNavigate()(0);


  return null;
}

export default Refresh;
