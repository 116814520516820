import { Box, styled } from "@mui/material"

// <Box sx={{
//   display: { lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' },
//   background: 'white',
//   height: { lg: 400, md: 310, sm: 230, xs: 180 },
//   margin: '0 auto',
//   alignItems: 'center',
//   justifyContent: 'center'
// }}></Box>

export default styled(Box)(({ theme }) => ({
  [theme.breakpoints.only('lg')]: {
    display: 'flex',
    height: 400,
  },
  [theme.breakpoints.only('md')]: {
    display: 'flex',
    height: 310,
  },
  [theme.breakpoints.only('sm')]: {
    display: 'flex',
    height: 230,
  },
  [theme.breakpoints.only('xs')]: {
    display: 'flex',
    height: 180,
  },
  // display: { lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' },
  background: 'white',
  // height: { lg: 400, md: 310, sm: 230, xs: 180 },
  margin: '0 auto',
  alignItems: 'center',
  justifyContent: 'center'
}));