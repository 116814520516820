import { Box, Button, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { BlueDivisor } from "../../../common/BlueDivisor/BlueDivisor";

export interface WhatsAppBannerProps {
  number: string;
  message?: string;
}

const whatsappUrl = 'https://wa.me'

const FlexBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  // background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 100%);',
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'space-between',
  marginTop: theme.spacing(3.5),
  marginBottom: theme.spacing(4),
  '& *': {
    flexBasis: '50%',
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
    },
  }
}));

const WhatsAppBanner: FC<WhatsAppBannerProps> = ({ number, message }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onButtonClick = () => {
    window.open(`${whatsappUrl}/${number}?text=${message}`, '_blank');
  };

  const Divisor = BlueDivisor();

  const printWAButton = () => {
    return (
      <Box sx={{ textAlign: 'center', marginTop: '24px' }}>
        <Button color="light" variant="contained" onClick={() => onButtonClick()}>Whatsapp</Button>
      </Box>
    );
  }

  return (
    <FlexBox>
      {!isMobile && (
        <Box sx={{ flex: { lg: 0.7, md: 0.7, sm: 1 }, textAlign: 'center' }}>
          <img src="doctor.png" alt="domicilio" />
        </Box>
      )}

      <Box sx={{ flex: { lg: 1, md: 1, sm: 1 }, justifyContent: 'flex-start', textAlign: 'right' }}>
        <Typography textAlign={isMobile ? 'center' : 'right'} variant={isMobile ? "h3" : "h1"} >Laboratorio médico a domicilio</Typography>
        <Divisor />
        {isMobile && (
          <img style={{ float: 'left', marginRight: 8 }} width="35%" src="doctor.png" alt="domicilio" />
        )}

        <Typography sx={{ padding: 0 }} variant="caption">Nuestro servicio de laboratorio médico te ofrece la comodidad</Typography>
        {/* <br /> */}
        <Typography variant="caption">y la tranquilidad de realizar tus análisis sin tener que sair de casa</Typography>
        {!isMobile && printWAButton()}
      </Box>
      {isMobile && printWAButton()}
    </FlexBox>
  )
};

export default WhatsAppBanner;
