import { Typography } from "@mui/material";
import { FC } from "react"
import { Link } from "react-router-dom";
import FlexBox from "../FlexBox/FlexBox";

const ErrorPage404: FC<{}> = () => {
  return (
    <FlexBox sx={{
      justifyContent: 'center',
      marginBottom: '48px',
      textAlign: 'center'
    }}>
      <img style={{ width: '60%' }} src="/404.png" alt="Página no encontrada" />
      <Typography variant="h6" style={{ fontSize: 40 }}>Página no econtrada</Typography>
      <Typography variant="h6">Lo setimos, la página que solicitó no pudo ser encontrada.</Typography>
      <br />
      <Typography variant="h6">Pero podemos llevarlo de nuevo a la <Link to="/">página de inicio</Link></Typography>
    </FlexBox>
  )
};

export default ErrorPage404;
