import { FC } from "react";
import { CenteredBox } from "../CenteredBox/CenteredBox";
import { Link } from "@mui/material";

const SocialMedia: FC<{}> = () => {
  return (
    <CenteredBox style={{ justifyContent: 'space-between', marginBottom: 24, width: 70 }}>
      <Link href="#"><img src="/icons/facebook.png" alt="Facebook" /></Link>
      <Link href="#"><img src="/icons/ig.png" alt="Instagram" /></Link>
    </CenteredBox>
  );
};

export default SocialMedia;
