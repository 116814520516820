import { Box } from "@mui/material";
import React, { FC, PropsWithChildren, useCallback } from "react";

interface WhatsAppButtonProps {
  number: string;
  message: string;
}

const WhatsAppWrapper: FC<PropsWithChildren<WhatsAppButtonProps>> = ({ number, message, children }) => {
  const onButtonClick = useCallback(() => {
    window.open(`whatsapp://send?phone=${number}&text=${message}`, '_blank');
  }, [number, message]);

  return (
    <Box sx={{ textAlign: 'center', marginTop: '24px' }}>
      <div onClick={() => onButtonClick()}>{children}</div>
    </Box>
  )
};

export default WhatsAppWrapper;
