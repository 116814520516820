import { FC } from "react";
import { Modal as MuiModal } from '@mui/material';

export type ModalProps = {
  open?: boolean;
  closable?: boolean;
  onClose?: (event: Event) => void;
  children: JSX.Element;
}

const Modal: FC<ModalProps> = ({ open = false, onClose, closable = true, children }) => {

  const handleClose = (event: Event) => {
    onClose?.(event);
  }

  return (
    <MuiModal
      data-testid="modal"
      open={open}
      onClose={handleClose}
    >
      {children}
    </MuiModal>
  );
}

export default Modal;
