import { FC } from "react";
import { BannerImage } from "../LandingPage/components/BannerImage/BannerImage";
import { Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { CenteredBox } from "../common/CenteredBox/CenteredBox";

const UsText = styled(Typography)(({ theme }) => ({
  lineHeight: '154%',
  textAlign: 'justify',
}));

const Us: FC<{}> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <CenteredBox>
        {isMobile
          ? <BannerImage alt="banner" src={`banner-us-xs.png`} />
          : <BannerImage alt="banner" src={`banner-us-lg.png`} />}
      </CenteredBox>

      <UsText
        sx={{
          lineHeight: '154%',
          marginTop: 5,
        }}
        variant="body1"
        textAlign='justify'
      >
        “BP LAB, Laboratorios Médicos” es una empresa 100% mexicana enfocada en ser en brindar servicios de la más alta calidad. Una empresa que ésta firmemente comprometida con sus valores y, en especial, con el bienestar de nuestros pacientes al ofrecer nuestro servicio de análisis clínicos y de gabinete.</UsText>
      <UsText>Somos un laboratorio enfocado siempre en la excelencia de todos y cada uno de nuestros procesos, dedicándonos al proceso de muestras clínicas con apoyo del laboratorio de referencia de análisis clínicos más grande de México, Orthin Referencia Especializada.</UsText>
      <UsText>
        Orthin Referencia Especializada ofrece un amplio repertorio de estudios de análisis clínicos con los más altos estándares de calidad, contando con acreditación por parte de la Entidad Mexicana de Acreditación (acreditación no. CL-031) y con presencia en toda la república.
      </UsText>
    </>
  );
}

export default Us;
