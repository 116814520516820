import React, { FC } from 'react';
import RibbonBox from './RibbonBox';
import RibbonText from './RibbonText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as LocationIcon } from '../../../img/location.svg';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { subsidiarySelector } from '../../../store/selectors';
import { setChooseSubsidiary } from '../../../store/reducers/subsidiary';


const BPRibbon: FC<{}> = () => {
  const dispatch = useDispatch();
  const preferredSubsidiary = useSelector(subsidiarySelector);

  return (
    <RibbonBox>
      <RibbonBox
        sx={{
          margin: '0 auto',
          maxWidth: 1480,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          onClick={() => { dispatch(setChooseSubsidiary(true)) }}>
          <LocationIcon style={{ paddingRight: 8 }} />
          <RibbonText>{preferredSubsidiary?.name ?? '-'}</RibbonText>
          <FontAwesomeIcon style={{ paddingLeft: 8 }} icon={faAngleDown} />
        </Box>
        {/* <RibbonText sx={{ justifyContent: 'flex-end' }}>Médicos | Empresas</RibbonText> */}
      </RibbonBox>
    </RibbonBox>
  );
};

export default BPRibbon;