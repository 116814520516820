import { FC } from "react"
import { CenteredBox } from "../CenteredBox/CenteredBox";
import { Typography } from "@mui/material";
import FlexBox from "../FlexBox/FlexBox";

const NoResultsFound: FC<{}> = () => {
  return (
    <CenteredBox>
      <FlexBox sx={{ marginTop: 4, textAlign: 'center' }}>
        <Typography variant="h2" fontWeight='normal'>No se encontraron resultados :(</Typography>
        <br></br>
        {/* <Typography variant="h3" fontWeight='normal'>Intenta buscando otra cosa</Typography> */}
      </FlexBox>
    </CenteredBox>

  );
};

export default NoResultsFound;
