import { Box, styled } from "@mui/material";

const FlexBox = styled(Box)(({ theme }) => ({
  flexFlow: 'row wrap',
  justifyContent: 'flex-start',
  '& *': {
    // flexBasis: '100%'
    // paddingRight: theme.spacing(6)
  }
}));

export default FlexBox;
