import { FC, useState } from "react";
import FlexBox from "../../common/FlexBox/FlexBox";
import HorizontalLinks from "../../common/HorizontalLinks/HorizontalLinks";
import { BlueDivisor } from "../../common/BlueDivisor/BlueDivisor";
import { Typography } from "@mui/material";


const Divisor = BlueDivisor('#f6f6f6');

interface StudySectionsProps {
  values: {
    title: string;
    value: string;
  }[];
}

const StudySections: FC<StudySectionsProps> = ({ values }) => {
  const links = values.map(v => ({ text: v.title, value: v.title }));
  const [selected, setSelected] = useState(links[0]?.text || '');

  if (values.length === 0) {
    return null;
  }

  const onSelectValue = (value: string) => {
    setSelected(value);
  };

  return (
    <FlexBox>
      <HorizontalLinks links={links} selected={selected} onSelectValue={onSelectValue} />
      <Divisor sx={{ position: 'relative', top: '-11px', zIndex: -1 }} />
      <Typography style={{ marginBottom: 40, whiteSpace: "pre-wrap" }} textAlign="justify" variant="body2">
        {values.find(v => v.title === selected)?.value || ''}
      </Typography>
    </FlexBox>
  );
};

export default StudySections;
