import { configureStore } from '@reduxjs/toolkit';
import subsidiariesReducer from './reducers/subsidiary';

export const store = configureStore({
  reducer: {
    subsidiaries: subsidiariesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch
