import { Box, Typography, styled } from "@mui/material";
import { FC } from "react";

const InstructiveBox = styled(Box)(({ theme }) => ({
  alignItems: 'end',
  display: 'flex',
  justifyContent: 'space-around',
  marginBottom: theme.spacing(8.75),
  marginTop: theme.spacing(8.75),
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(5),
  }
}));


const Box2 = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    margin: `${theme.spacing(4)} auto`,
    textAlign: 'center',
  },
  '& img': {
    display: 'block',
    margin: '0 auto',
    width: '25%',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(10)
    }
  }
}));



const Instructive: FC<{}> = () => {
  return (
    <InstructiveBox>
      <Box2>
        <img alt="Elige" src="icons/cuaderno.png" />
        <Typography>Elige tu estudio</Typography>
      </Box2>
      <Box2>
        <img alt="Cotiza" src="icons/buscar.png" />
        <Typography>Cotiza el estudio</Typography>
      </Box2>
      <Box2>
        <img alt="Visitanos" style={{ marginBottom: 16 }} src="icons/carro.png" />
        <Typography>Visítanos o iremos a ti</Typography>
      </Box2>
    </InstructiveBox>
  );
};

export default Instructive;
