import { Box, Typography, styled } from "@mui/material";
import { FC } from "react";

export interface MedicAreaProps {
  name: string;
  iconUrl: string;
  iconAlt?: string;
  onClick?: () => void;
};

const MedicAreaBox = styled(Box)(({ theme }) => ({
  background: 'white',
  cursor: 'pointer',
  display: 'flex',
  flexGrow: 3,
  flexShrink: 3,
  boxShadow: '2px 2px 14px rgba(0, 0, 0, 0.1)', borderRadius: 1,
  alignItems: 'center',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`, //'16px 24px',
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(2.25)} ${theme.spacing(2.5)}`,
  }
}));

const MedicArea: FC<MedicAreaProps> = (props) => {
  const icon = props.iconUrl || 'icons/resp.png';
  return (
    <MedicAreaBox onClick={props.onClick}>
      <Box sx={{ flex: 1, maxWidth: 64 }}>
        <img src={icon} width="100%" alt={props.iconAlt} />
      </Box>

      <Typography data-testid={`medic-area-name-${props.name}`} marginLeft="20px" flex={2} variant="body2">{props.name}</Typography>
    </MedicAreaBox>
  );
};

export default MedicArea;
