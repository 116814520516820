import { BPConfig } from "../types";
import { ServiceBase } from "./ServiceBase";

export class ConfigService extends ServiceBase {
  constructor(url?: string) {
    super(url);
  }

  async getConfig(): Promise<BPConfig | undefined> {
    return this.genericRequest<BPConfig | undefined>({
      url: '/config.json',
      method: 'GET'
    });
  }
}