import { Box, Typography, styled } from "@mui/material";
import { FC } from "react";

const LegalBox = styled(Box)(({ theme }) => ({
  background: theme.palette.grey[100],
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
  '> p': {
    color: theme.palette.secondary.main,
    fontSize: theme.spacing(1.75),
    padding: theme.spacing(0.5),
    textAlign: 'center'
  },
  [theme.breakpoints.down('md')]: {
    padding: `0 ${theme.spacing(2.25)}`,

  }
}));

const Legal: FC<{}> = () => {
  return (
    <LegalBox>
      <Typography>Las acreditaciones y certificaciones pertenecen a nuestro centro analítico Orthin Referencia Especializada SA de CV.</Typography>
      <Typography>Consulte a su médico, es el único facultado para indicar el tipo de estudio de laboratorio a realizar en cada paciente de acuerdo a su historia clínica e interpretar los resultados.</Typography>
      <Typography>Los precios y promociones están sujetos a cambios sin previo aviso.</Typography>
    </LegalBox>);
};

export default Legal;
