import { createTheme } from "@mui/material";

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }

  interface Palette {
    light: Palette['primary']
  }

  interface PaletteOptions {
    light?: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    light: true
  }
}

let theme = createTheme();

const mobileViewport = theme.breakpoints.down('sm');

export const BPTheme = createTheme(theme, {
  palette: {
    primary: {
      main: "#094FA4",
      light: "#009EE9",
      dark: "#151C29"
    },
    secondary: {
      main: "#4E71B1",
      light: "#A7B8D8"
    },
    grey: {
      "100": "#F2F2F7",
      "200": "#E5E5EA",
      "300": "#D1D1D6",
      "400": "#C7C7CC",
      "500": "#AEAEB2",
      "600": "#AEAEB2",
    },
    light: {
      main: "#009EE9",
      contrastText: 'white',
    }
  },
  typography: {
    fontFamily: [
      'SF Display',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 22,
    body1: {
      fontFamily: 'SF Display',
      fontSize: 22,
      lineHeight: 1.4,
      paddingBottom: theme.spacing(2),
      [mobileViewport]: {
        fontSize: 17,
      }
    },
    h1: {
      fontFamily: 'Modernist',
      fontSize: 41,
      fontWeight: 'bold',
      [mobileViewport]: {
        fontSize: 34,
      }
    },
    h2: {
      fontFamily: 'Modernist',
      fontSize: 34,
      fontWeight: 'bold',
      [mobileViewport]: {
        fontSize: 28,
      }
    },
    h3: {
      fontFamily: 'Modernist',
      fontSize: 28,
      fontWeight: 'bold',
      paddingBottom: theme.spacing(2),
      [mobileViewport]: {
        fontSize: 22,
      }
    },
    h4: {
      fontFamily: 'Modernist',
      fontSize: 25,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      }
    },
    h5: {
      fontFamily: 'Modernist',
      fontSize: 22,
      fontWeight: 'bold',
      [mobileViewport]: {
        fontSize: 17,
      }
    },
    h6: {
      fontFamily: 'Modernist',
      fontSize: 17,
      [mobileViewport]: {
        fontSize: 13,
      }
    },
    caption: {
      fontFamily: "SF Display",
      fontSize: 17,
      [mobileViewport]: {
        fontSize: 14,
      }
    },
    body2: {
      fontFamily: "SF Display",
      fontSize: 18,
      fontWeight: 'light',
      [mobileViewport]: {
        fontSize: 17,
      }
    },
    button: {
      fontFamily: "Modernist",
      fontWeight: 'lighter',
      textTransform: 'capitalize'
    },
    link: {
      fontFamily: "Modernist",
      fontWeight: 'lighter'
    },
    footer: {
      color: 'white',
      padding: 0
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          fontSize: 16,
          textTransform: 'capitalize',
        },
        root: {
          fontSize: 16,
          padding: '10px 32px'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: 'Modernist',
          fontSize: 16,
          padding: 0
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          fontFamily: 'Modernist',
          fontSize: 16,
          '& .MuiInputLabel-root': {
            fontSize: 16,
            top: -8
          }
        },
        '& .MuiInputLabel-root': {
          paddingBottom: 4
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: '#111',
          height: 40,
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: theme.spacing(2)
        }
      }
    }
  }
});
