export class ServiceBase {
  apiUrl: string;

  constructor(url?: string) {
    this.apiUrl = url || process.env.REACT_APP_BP_HOST || '';
  }

  async getRequest<T>({
    url,
    method,
    body,
  }: { url: string, method: string, body?: any }) {
    const result = await fetch(this.apiUrl + url, {
      method,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: '*/*',
        'bp-api-key': 'bp-asdfASDF1234567890',
      }),
      body,
    });

    if (result.status > 399) {
      const error = await result.json();
      console.error(error.message);
      return {
        data: [],
        page: 1,
        epp: 10,
        total: 0,
      };
    }

    return result.json() as T;
  }

  async genericRequest<T>({
    url,
    method,
    body,
  }: { url: string, method: string, body?: any }) {
    const result = await fetch(this.apiUrl + url, {
      method,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: '*/*',
        'bp-api-key': 'bp-asdfASDF1234567890',
      }),
      body,
    });

    if (result.status > 399) {
      const error = await result.json();
      console.error(error.message);
      return undefined;
    }

    return result.json() as T;
  }
}

