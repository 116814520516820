import { FC } from "react"

export interface SeparatorProps {
  color?: string;
}

const Separator: FC<SeparatorProps> = ({ color = 'white' }) => {
  return <hr style={{ borderColor: color }} />;
};

export default Separator;
