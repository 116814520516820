import { Box, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";

const StyledBar = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  color: 'white',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(3),
  paddingTop: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

  },
  '> p': {
    padding: 0
  },
  '> .right': {
    '> p': {
      fontSize: theme.spacing(2.375),
      padding: 0
    },
    '> *': {
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(2),
      },
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(1.6),
      },
    }
  }
}));

const FooterLogo = styled('img')(({ theme }) => ({
  height: theme.spacing(8)
  ,
}));

const AllianceBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    alignItems: "center",
    display: 'flex',
    margin: 0
  },
  [theme.breakpoints.down('md')]: {
    display: 'block',
    '> *': {
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2)
      }
    }
  }
}));

const AllianceLogo = styled('img')(({ theme }) => ({
  height: theme.spacing(4.5)
  ,
}));

const AllianceBar: FC<{}> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledBar>
      <Link style={{ margin: isMobile ? '0 auto' : '', marginBottom: isMobile ? '16px' : 0 }} to="/"><FooterLogo src="/bp-white.png" alt="BP Lab" /></Link>
      <AllianceBox className="right">
        <Typography variant="body1">En colaboración con:</Typography>
        <AllianceLogo src="/orthin-white.png" alt="Laboratiorio Orthin" />
        <Typography variant="body1">Certificados por:</Typography>
        <AllianceLogo src="/icons/indre-white.png" alt="INDRE" />
        <AllianceLogo src="/icons/ema-white.png" alt="ema" />
        <AllianceLogo src="/icons/cofepris-white.png" alt="COFEPRIS" />
      </AllianceBox>

    </StyledBar>
  );
}

export default AllianceBar;
